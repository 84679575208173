import React, { ReactElement } from "react"
import DemoCTA from "../../components/DemoCTA/DemoCTA"
import Discover from "../../components/HomePage/Discover"
import { nextProductsList, productsList } from "../../constants/products"
import DefaultLayout from "../../templates/DefaultLayout"
import {
  DownloadSample,
  ProductFeature,
  ProductView,
  WatchDemo,
} from "../../components/Product/ProductComponents"
import Seo from "../../components/seo"
import HubspotForm from "react-hubspot-form"

const features = [
  {
    title: "Agile project management for litigation:",
    features: [
      "Easily task, manage and coordinate your team (whether working remote, in the office, or on the go).",
      "See the status and next required action for each case at a glance.",
      "Watch the progress of your cases in real time.",
    ],
  },
  {
    title: "Modern tools for coordination:",
    features: [
      "Ensure that tasks are seen and actioned, not buried six feet under in someone's inbox!",
      "Like digital post-it notes...scroll the comment stream on the file cover sheet to get the latest on the progress of the file.",
      "Principals - comment, task and tag your team for easy supervision.",
    ],
  },
  {
    title: "Document allocation fixed!",
    features: [
      "Load documents to multiple matters at once.",
      "No more lost documents - track where documents have been allocated and by which member of your team.",
      "Get notifications when new documents have been allocated to your matters",
      "Apply litigation-relevant attributes to documents, such as source, document date, document type, and relevance.",
    ],
  },
  {
    title:
      "Electronic briefing made easy – barristers, share a link for solicitors to upload your brief. View it all as a tabbed electronic bundle.",
  },
  {
    title: "Smart summaries - chronology is king!",
    features: [
      "Summarise evidence as it comes in to build up a ready history of the case.",
      "Create breakout summaries by reference to issues, documents, or groups of documents which all feed into the Master Chronology.",
      "Add file notes to the Master Chronology to keep everyone on the same page.",
      "Tag entries with colour - coded relevance tags.",
      "One click to open to the relevant page of referenced documents.",
    ],
  },
  {
    title: "Secure your matters – see and control who has access to your files",
  },
  {
    title: "File notes made easy:",
    features: [
      "Filter, search and scroll your file notes like a social media feed to quickly update yourself.",
      "No more jumping around! File note all of your matters from one place.",
      "Attention file notes to team members.",
      "Get notifications of new file notes on your matters",
    ],
  },
  {
    title: "And so much more!",
  },
]

export default function EcoLossAnalyser() {
  return (
    <DefaultLayout>
      <Seo
        title="Case Builder"
        description="Next Gen Automation, Coordination and Innovation to Multiply Your Team and Scale Your Practice"
      />
      <div className="flex text-br-primary-blue flex-col">
        <ProductView
          {...{
            ...nextProductsList[0],
            description:
              "Next Gen Automation, Coordination and Innovation to Multiply Your Team and Scale Your Practice",
          }}
        />
        <WatchDemo
          title={"Watch the Concept Video"}
          video={"https://www.youtube.com/embed/bhyCSE_OSP8"}
        />
        <div className="flex flex-col px-4 max-w-[900px] mx-auto py-10">
          <h2 className="text-xl md:text-3xl font-extrabold mb-6">
            Please Register Your Interest
          </h2>
          <HubspotForm
            portalId="19910559"
            formId="09f29b8f-bc60-409c-8af1-cea38ebf518e"
            onSubmit={() => console.log("Submit!")}
            loading={<div>Loading...</div>}
            css="dsd"
          />
        </div>
        <ProductFeature title={"It's Time for an Upgrade!"}>
          <p>
            The LawLite Case Builder will deliver a full range of long overdue
            upgrades for lawyers.
          </p>
          <p className="my-6">
            Finally, technology that significantly multiplies the productivity
            of your practice!
          </p>
          <ul className="list-disc lg:ml-16 ml-8">
            {features.map(feature => (
              <li key={feature.title} className="mt-6">
                {feature.title}
                {feature.features && (
                  <ul className="list-[circle] d lg:ml-10 ml-4">
                    {feature.features.map(feature => (
                      <li key={feature} className="mt-6">
                        {feature}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </ProductFeature>
      </div>
    </DefaultLayout>
  )
}
