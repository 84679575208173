import React from "react"
import { MediaRender } from "../HomePage/NewsAndUpdates"

export const ProductView = (product: {
  name: string
  image: string
  description: string
}) => {
  return (
    <div className="px-4 mb-6 mt-6 flex flex-col items-center justify-center">
      <h1 className="text-3xl md:text-5xl mt-2 mb-6">{product.name}</h1>
      <img src={product.image} alt={product.name} />
      <p className="text-lg md:text-2xl mt-2">{product.description}</p>
    </div>
  )
}

export const WatchDemo = ({
  video,
  title = " Watch the Demo Video",
}: {
  video: string
  title?: string
}) => {
  return (
    <div className="bg-br-primary-blue pt-8 pb-20 flex flex-col items-center px-8 w-full">
      <h2 className="text-white font-bold text-2xl md:text-4xl">{title}</h2>
      <div className="rounded-lg mt-6 h-[250px] sm:h-[450px] w-[800px] overflow-hidden max-w-[100%] relative">
        <MediaRender media={video} />
      </div>
    </div>
  )
}

export const DownloadSample = ({
  link,
  text,
}: {
  link: string
  text: string
}) => {
  return (
    <div className="py-20 flex flex-col items-center justify-center text-center">
      <a
        href={link}
        download
        className="text-br-primary-blue font-bold hover:text-br-primary-blue text-xl lg:text-2xl underline"
      >
        {text}
      </a>
    </div>
  )
}

export const ProductFeature = ({
  children,
  title,
}: {
  children?: React.ReactNode
  title: string
}) => {
  return (
    <div className="py-14 bg-br-primary-blue text-white text-lg md:text-xl  px-4">
      <div className={"max-w-[950px] mx-auto"}>
        <h2 className="text-white font-bold text-2xl md:text-3xl text-center">
          {title}
        </h2>
        <div className="mt-6">{children}</div>
      </div>
    </div>
  )
}
